<template>
    <section>
        <div class="container">
            <TitleBar title="Stok Hareketi Kayıtları" :modi="modi" />

            <b-field class="columns box" label="Filtre Alanları" grouped group-multiline>
                <b-field class="column is-2" label="ID">
                    <b-input v-model="movements.filters.id"></b-input>
                </b-field>
                <b-field class="column is-2" label="Tür">
                    <b-select placeholder="Seçiniz.." v-model="movements.filters.class" expanded>
                        <option value="Giriş">Giriş</option>
                        <option value="Çıkış">Çıkış</option>
                        <option value="Değişim">Değişim</option>
                        <option value="Diğer">Diğer</option>
                    </b-select>
                </b-field>
                <b-field class="column is-5" label="Hareket Açıklaması">
                    <b-input v-model="movements.filters.name"></b-input>
                </b-field>
                <b-field class="column is-3" label="Tarih">
                    <b-input v-model="movements.filters.date"></b-input>
                </b-field>
                <b-field class="column is-2" label="Malzeme Sınıfı">
                    <b-select
                        placeholder="Seçiniz.."
                        v-model="movements.filters.material.class"
                        expanded
                    >
                        <option value="Hammadde">Hammadde</option>
                        <option value="Yarı Mamül">Yarı Mamül</option>
                        <option value="Mamül">Mamül</option>
                        <option value="Ticari">Ticari</option>
                        <option value="Diğer">Diğer</option>
                    </b-select>
                </b-field>
                <b-field class="column is-3" label="Malzeme Kodu">
                    <b-input v-model="movements.filters.material.code"></b-input>
                </b-field>
                <b-field class="column is-4" label="Malzeme Adı">
                    <b-input v-model="movements.filters.material.name"></b-input>
                </b-field>
                <b-field class="column is-3" label="Parti No">
                    <b-input v-model="movements.filters.lotnum"></b-input>
                </b-field>
            </b-field>

            <b-field class="columns box mt-2" label="Stok Hareketi Tablosu" grouped group-multiline>
                <b-field class="column is-12" grouped group-multiline>
                    <b-button
                        class="column"
                        type="is-info is-light"
                        icon-left="magnify"
                        label="Ara"
                        @click="movements_read"
                    />
                    <b-button
                        class="column ml-1"
                        type="is-success is-light"
                        icon-left="plus"
                        label="Yeni"
                        @click="movements_new"
                    />
                    <b-button
                        class="column ml-1"
                        type="is-success is-light"
                        icon-left="content-copy"
                        label="Ref. Al"
                        @click="movements_ref"
                        disabled
                    />
                    <b-button
                        class="column ml-1"
                        type="is-info is-light"
                        icon-left="eye"
                        label="Goruntule"
                        :disabled="!movements.selected.id"
                        @click="movements_show"
                    />
                    <b-button
                        class="column ml-1"
                        type="is-warning is-light"
                        icon-left="pencil"
                        label="Duzenle"
                        :disabled="!movements.selected.id"
                        @click="movements_edit"
                    />
                    <b-button
                        class="column ml-1"
                        type="is-danger is-light"
                        icon-left="delete"
                        label="Sil"
                        :disabled="!movements.selected.id"
                        @click="movements_delete"
                    />
                    <b-button
                        class="column ml-1"
                        type="is-link is-light"
                        icon-left="printer"
                        label="Yazdir"
                        @click="movements_print"
                        disabled
                    />
                    <b-button
                        class="column ml-1"
                        type="is-link is-light"
                        icon-left="import"
                        label="Içe Aktar"
                        disabled
                    />
                    <export-excel :data="movements.data" :fields="movements.fields">
                        <b-button
                            class="column ml-1"
                            type="is-link is-light"
                            icon-left="export"
                            label="Dışa Aktar"
                        />
                    </export-excel>
                </b-field>

                <b-table
                    class="column is-12"
                    :mobile-cards="false"
                    :data="movements.data"
                    :total="movements.total"
                    :current-page.sync="movements.page"
                    :selected.sync="movements.selected"
                    :sort-multiple-data.sync="movements.sorts"
                    :loading="$apollo.queries.readStockMovements.loading"
                    @sort="movements_sort"
                    @sorting-priority-removed="movements_nosort"
                    backend-pagination
                    backend-sorting
                    sort-multiple
                    striped
                    hoverable
                    narrowed
                    paginated
                    pagination-simple
                >
                    <template slot="empty">
                        <div class="is-12 has-text-centered">
                            Stok hareketi kaydı bulunamadı.
                        </div>
                    </template>

                    <b-table-column field="id" label="ID" v-slot="props" sortable>{{
                        props.row.id.slice(-5)
                    }}</b-table-column>
                    <b-table-column field="class" label="Tür" v-slot="props" sortable>{{
                        props.row.class
                    }}</b-table-column>
                    <b-table-column field="name" label="Açıklama" v-slot="props" sortable>{{
                        props.row.name
                    }}</b-table-column>
                    <b-table-column field="material.class" label="Malzeme Sınıfı" v-slot="props">{{
                        props.row.material.class
                    }}</b-table-column>
                    <b-table-column field="material.code" label="Malzeme Kodu" v-slot="props">{{
                        props.row.material.code
                    }}</b-table-column>
                    <b-table-column field="material.name" label="Malzeme Adı" v-slot="props">{{
                        props.row.material.name
                    }}</b-table-column>
                    <b-table-column field="lotnum" label="Parti No" v-slot="props" sortable>{{
                        props.row.lotnum
                    }}</b-table-column>
                    <b-table-column
                        field="quantity"
                        label="Miktar (KG)"
                        v-slot="props"
                        numeric
                        sortable
                        >{{ props.row.quantity }} KG</b-table-column
                    >
                    <b-table-column field="timestamp" label="Tarih" v-slot="props" sortable>{{
                        new Date(props.row.timestamp).toLocaleDateString("tr-TR", {
                            weekday: "long",
                            year: "numeric",
                            month: "numeric",
                            day: "numeric",
                        })
                    }}</b-table-column>
                </b-table>
            </b-field>
        </div>
    </section>
</template>

<script>
import gql from "graphql-tag";
import TitleBar from "@/components/TitleBar.vue";

export default {
    name: "StockMovementTable",
    components: {
        TitleBar,
    },
    data: () => ({
        modi: "",
        movements: {
            page: 1,
            take: 20,
            total: 9999,
            data: [],
            sorts: [],
            filters: {
                material: {},
            },
            selected: {},
            fields: {
                ID: "id",
                Sınıf: "class",
                Açıklama: "name",
                "Malzeme ID": "material.id",
                "Malzeme Sınıfı": "material.class",
                "Malzeme Kodu": "material.code",
                "Malzeme Adı": "material.name",
                "Parti No:": "lotnum",
                Miktar: "quantity",
                Tarih: "timestamp",
            },
        },
    }),
    mounted() {
        this.movements_read();
    },
    methods: {
        movements_read() {
            this.$apollo.queries.readStockMovements.start();
            this.$apollo.queries.readStockMovements.refetch();
        },
        movements_new() {},
        movements_ref() {},
        movements_show() {
            console.log(this.movements.selected.sourcetable);
            console.log(this.movements.selected.id_source);
            let path = "";

            switch (this.movements.selected.sourcetable) {
                case "MaterialStock":
                    return;
                case "ProductionConfirmHead":
                    path = "/uretim/onay/detay";
                    break;
                case "InvoiceHead":
                    path = "/irsaliye/detay";
                    break;
                default:
                    return;
            }

            this.$router.push({
                path,
                query: { id: this.movements.selected.id_source, modi: "show" },
            });
        },
        movements_edit() {
            let path = "";

            switch (this.movements.selected.sourcetable) {
                case "MaterialStock":
                    return;
                case "ProductionConfirmHead":
                    path = "/uretim/onay/detay";
                    break;
                case "InvoiceHead":
                    path = "/irsaliye/detay";
                    break;
                default:
                    return;
            }
            this.$router.push({
                path,
                query: { id: this.movements.selected.id_source, modi: "edit" },
            });
        },
        movements_delete() {},
        movements_print() {},
        movements_export() {},
        movements_sort(field, order) {
            this.movements_nosort(field);
            this.movements.sorts.push({ field, order });
        },
        movements_nosort(field) {
            this.movements.sorts.forEach((e, i, a) => {
                if (e.field == field) a.splice(i, 1);
            });
        },
    },
    apollo: {
        readStockMovements: {
            query: gql`
                query readStockMovements($config: String) {
                    readStockMovements(config: $config) {
                        id
                        class
                        name
                        material {
                            id
                            class
                            code
                            name
                        }
                        lotnum
                        quantity
                        id_source
                        sourcetable
                        timestamp
                    }
                }
            `,
            variables() {
                var sorts = [];
                this.movements.sorts.forEach((e) =>
                    sorts.push({ column: e.field, order: e.order })
                );
                for (var filter in this.movements.filters)
                    if (!this.movements.filters[filter]) delete this.movements.filters[filter];

                return {
                    config: JSON.stringify({
                        sorts,
                        take: this.movements.take,
                        filters: this.movements.filters,
                        skip: (this.movements.page - 1) * this.movements.take,
                    }),
                };
            },
            update(data) {
                this.movements.data = data.readStockMovements;
            },
        },
    },
};
</script>
